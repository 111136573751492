<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible2" append-to-body width="60%" :before-close="handleClose">
      <!-- 农机 -->
      <div class="content" v-if="activeIndex==1">
        <el-form :key="activeIndex" label-position="left" ref="form" :inline="true" :model="form" label-width="80px"
          :rules="formRules">
          <div class="ItemIpt">
            <el-form-item label="车牌号" label-width="120px" prop="carNumber">
              <el-input v-model="form.carNumber" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="车架号" label-width="120px" prop="vehicleIdentificationNumber">
              <el-input v-model="form.vehicleIdentificationNumber" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="车辆品牌" label-width="120px" prop="carBrand">
              <el-input v-model="form.carBrand" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="车辆类型" label-width="120px" prop="carType">
              <el-select :disabled="type" v-model="form.carType" placeholder="请选择">
                <el-option v-for="item in carTypeList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="作业类型" label-width="120px" prop="jobType">

              <!-- <el-cascader ref="jobType" v-model="form.jobType" :props="jobProps" :options="jobTypeList"
                :show-all-levels="false" @change="regionChange2"></el-cascader> -->
              <el-select v-model="form.jobType" placeholder="请选择" :disabled="type">
                <el-option v-for="item in jobTypeList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生产厂家" label-width="120px" prop="manufacturer">
              <el-input v-model="form.manufacturer" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="所有人" label-width="120px" prop="allUser">
              <el-input v-model="form.allUser" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="所有人手机号" label-width="120px" prop="userPhone">
              <el-input v-model="form.userPhone" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="生产日期" label-width="120px" prop="manufactureTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form.manufactureTime" type="date" placeholder="选择日期"
                :disabled="type">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="绑定终端" label-width="120px" prop="terminalId">
              <!-- <el-cascader ref="terminalIdType" v-model="form.terminalId" :props="terminalIdType"
                :options="terminalIdTypeList" @change="regionChange3"></el-cascader> -->
              <el-select v-model="form.terminalId" @change="handleChange" placeholder="请选择" :disabled="type">
                <el-option v-for="item in terminalIdTypeList" :key="item.id" :label="item.terminalCode"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="up-img">
            <el-form-item label="照片" prop="files">
              <div class="ItemBox">
                <!-- <div class="icon">
                  <i class  =" el-icon-warning-outline"></i>
                </div> -->

                <el-upload :class="type? 'no-image' : '' " action="" class="upload-demo" :auto-upload="false" 
                  ref="upload" :on-change="uploadImages" list-type="picture-card" :file-list="files2">
                  <i class="el-icon-plus" v-if="!type"></i>
                  <!-- 这里是对应图片的按钮 -->
                  <div slot="file" slot-scope="{ file }">
                    <el-image ref="preview2" style="height: 115px" class="el-upload-list__item-thumbnail"
                      :src="file.url" :preview-src-list="BigPicUrl" alt="" :initial-index=0 />

                    <span class="el-upload-list__item-actions">
                      <span v-if=" !file.isShow" class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview2(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!type" class="el-upload-list__item-delete" @click="handleRemove2(file,files2)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
              </div>


            </el-form-item>


          </div>

        </el-form>
      </div>
      <!-- 机具 -->
      <div class="content" v-if="activeIndex==2">
        <el-form :key="activeIndex" label-position="left" ref="form2" :inline="true" :model="form2" label-width="80px"
          :rules="formRules2">
          <div class="ItemIpt">
            <el-form-item label="机具编号" label-width="120px" prop="implementCode">
              <el-input v-model="form2.implementCode" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="品牌" label-width="120px" prop="brand">
              <el-input v-model="form2.brand" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="生产厂商" label-width="120px" prop="manufacturer">
              <el-input v-model="form2.manufacturer" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="机具类型" label-width="120px" prop="machineType">
              <!-- <el-cascader  label-width="120px" ref="machineType" v-model="form2.machineType" :props="machineTypeProps"
                :options="machineTypeList" :show-all-levels="false" @change="regionChange4"></el-cascader> -->
              <el-select v-model="form2.machineType" placeholder="请选择" :disabled="type">
                <el-option v-for="item in machineTypeList" :key="item.id" :label="item.description" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="绑定终端" label-width="120px" prop="terminalIdTypeList">
              <!-- <el-cascader ref="terminalIdType2" v-model="form2.terminalId" :props="terminalIdType"
                :options="terminalIdTypeList" :show-all-levels="false" @change="regionChange5"></el-cascader> -->
              <el-select v-model="form2.terminalId" @change="handleChange" placeholder="请选择" :disabled="type">
                <el-option v-for="item in terminalIdTypeList" :key="item.id" :label="item.terminalCode"
                  :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
            <el-form-item label="所有人手机号" label-width="120px" prop="allPhone">
              <el-input v-model="form2.allPhone" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="所有人姓名" label-width="120px" prop="allUser">
              <el-input v-model="form2.allUser" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="作业类型" label-width="120px" prop="jobType">
              <!-- <el-cascader ref="jobType" v-model="form.jobType" :props="jobProps" :options="jobTypeList"
                                :show-all-levels="false" @change="regionChange2"></el-cascader> -->
              <el-select v-model="form2.jobType" placeholder="请选择" :disabled="type">
                <el-option v-for="item in JobsTypeList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="up-img">
            <el-form-item label="照片" prop="files">
              <div class="ItemBox">
                <!-- <div class="icon">
                              <i class  =" el-icon-warning-outline"></i>
                            </div> -->

                <el-upload :class="type? 'no-image' : '' " action="" class="upload-demo" :auto-upload="false"
                  ref="upload" :on-change="uploadImages" list-type="picture-card" :file-list="files2">
                  <i class="el-icon-plus" v-if="!type"></i>
                  <!-- 这里是对应图片的按钮 -->
                  <div slot="file" slot-scope="{ file }">
                    <el-image ref="preview2" style="height: 115px" class="el-upload-list__item-thumbnail"
                      :src="file.url" :preview-src-list="BigPicUrl" alt="" :initial-index=0 />

                    <span class="el-upload-list__item-actions">
                      <span v-if=" !file.isShow" class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview2(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!type" class="el-upload-list__item-delete" @click="handleRemove2(file,files2)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
              </div>


            </el-form-item>
          </div>

        </el-form>
      </div>
      <!--  终端-->
      <div class="content" v-if="activeIndex==3">
        <el-form :key="activeIndex" label-position="left" ref="form3" :inline="true" :model="form3" label-width="80px"
          :rules="formRules3">
          <div class="ItemIpt">
            <el-form-item label="终端编号" label-width="120px" prop="terminalCode">
              <el-input v-model="form3.terminalCode" :disabled="type"></el-input>
            </el-form-item>

            <el-form-item label="终端厂商" label-width="120px" prop="terminalMerchant">
              <el-cascader :disabled="type" label-width="120px" ref="terminalMerchant" v-model="form3.terminalMerchant"
                :props="terminalMerchantProps" :options="terminalIdTypeList" :show-all-levels="false"
                @change="regionChange6"></el-cascader>
            </el-form-item>
          </div>
          <!-- <div class="ItemIpt">
        <el-form-item label="车架号" label-width="120px" prop="vehicleIdentificationNumber">
                      <el-input v-model="form3.vehicleIdentificationNumber" :disabled="type"></el-input>
                    </el-form-item>

            <el-form-item label="车辆类型" label-width="120px" prop="carType">
              <el-select label-width="120px" :disabled="type" v-model="form3.carType" placeholder="请选择">
                <el-option v-for="item in carTypeList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
          </div> -->
          <div class="ItemIpt">
            <el-form-item label="绑定农机具" label-width="120px" prop="">
              <!-- <el-input v-model="form3.implementId"></el-input> -->
              <!-- <el-cascader  label-width="120px" ref="implementId" v-model="form3.implementId"
                :options="implementIdList" :show-all-levels="false" @change="regionChange8"></el-cascader> -->
              <el-select ref="equipment" :disabled="type" v-model="form3.temporarilyId" @change="handleSelectChange"
                placeholder="请选择">
                <el-option v-for="item in implementIdList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所有人手机号" label-width="120px" prop="allUserPhone">
              <el-input label-width="120px" v-model="form3.allUserPhone" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="开始时间" label-width="120px" prop="startTime">
              <!-- <el-input v-model="form3.phone"></el-input> -->
              <el-date-picker :disabled="type" label-width="120px" value-format="yyyy-MM-dd" v-model="form3.startTime"
                type="date" placeholder="选择日期" @change="changeStartTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" label-width="120px" prop="endTime">
              <!-- <el-input v-model="form3.endTime"></el-input> -->
              <el-date-picker :disabled="type" label-width="120px" value-format="yyyy-MM-dd" v-model="form3.endTime"
                type="date" placeholder="选择日期" @change="changeEndTime">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="所有人姓名" label-width="120px" prop="allUser">
              <el-input v-model="form3.allUser" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="up-img">
            <el-form-item label="照片" prop="files">
              <div class="ItemBox">
                <!-- <div class="icon">
                        <i class  =" el-icon-warning-outline"></i>
                      </div> -->

                <el-upload :class="type? 'no-image' : '' " action="" class="upload-demo" :auto-upload="false"
                  ref="upload" :on-change="uploadImages" list-type="picture-card" :file-list="files2">
                  <i class="el-icon-plus" v-if="!type"></i>
                  <!-- 这里是对应图片的按钮 -->
                  <div slot="file" slot-scope="{ file }">
                    <el-image ref="preview2" style="height: 115px" class="el-upload-list__item-thumbnail"
                      :src="file.url" :preview-src-list="BigPicUrl" alt="" :initial-index=0 />

                    <span class="el-upload-list__item-actions">
                      <span v-if=" !file.isShow" class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview2(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!type" class="el-upload-list__item-delete" @click="handleRemove2(file,files2)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
              </div>


            </el-form-item>


          </div>

        </el-form>

      </div>
      <!-- 拖拉机-->
      <div class="content" v-if="activeIndex==4">
        <el-form :key="activeIndex" label-position="left" ref="form4" :inline="true" :model="form4" label-width="120px"
          :rules="formRules4">
          <div class="ItemIpt">
            <el-form-item label="所有人" label-width="120px" prop="allUser">
              <el-input v-model="form4.allUser" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="车辆品牌" label-width="120px" prop="carBrand">
              <el-input v-model="form4.carBrand" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="车牌号" label-width="120px" prop="carNumber">
              <el-input v-model="form4.carNumber" :disabled="type||isTrue"></el-input>
            </el-form-item>
            <el-form-item label="车辆类型" label-width="120px" prop="carType">
              <el-select :disabled="type" v-model="form4.carType" placeholder="请选择">
                <el-option v-for="item in carTypeList" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="生产日期" label-width="120px" prop="manufactureTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="form4.manufactureTime" type="date" placeholder="选择日期"
                :disabled="type">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="生产厂家" label-width="120px" prop="manufacturer">
              <el-input v-model="form4.manufacturer" :disabled="type"></el-input>
            </el-form-item>
          </div>
          <div class="ItemIpt">
            <el-form-item label="所有人手机号" label-width="120px" prop="userPhone">
              <el-input v-model="form4.userPhone" :disabled="type"></el-input>
            </el-form-item>
            <el-form-item label="车架号" label-width="120px" prop="vehicleIdentificationNumber">
              <el-input v-model="form4.vehicleIdentificationNumber" :disabled="type||isTrue"></el-input>
            </el-form-item>
          </div>
          <div class="up-img">
            <el-form-item label="照片" prop="files">
              <div class="ItemBox">
                <!-- <div class="icon">
                  <i class  =" el-icon-warning-outline"></i>
                </div> -->

                <el-upload :class="type? 'no-image' : '' " action="" class="upload-demo" :auto-upload="false"
                  ref="upload" :on-change="uploadImages" list-type="picture-card" :file-list="files2">
                  <i slot="default" class="el-icon-plus" v-if="!type"></i>

                  <!-- 这里是对应图片的按钮 -->
                  <div slot="file" slot-scope="{ file,index }">
                    <el-image ref="preview2" style="height: 115px" class="el-upload-list__item-thumbnail"
                      :src="file.url" :preview-src-list="BigPicUrl" alt="" :initial-index=0 />

                    <span class="el-upload-list__item-actions">
                      <span v-if=" !file.isShow" class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview2(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!type" class="el-upload-list__item-delete" @click="handleRemove2(file,files2)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>

                <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
              </div>
            </el-form-item>
          </div>

        </el-form>
      </div>
      <div class="footer" v-if="!type">
        <el-button type="primary" @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="submitForm()">提交</el-button>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
  import { uploadFile } from "@/api/fileApi";
  import { insertAgriculturalImplement, carType, selectCompanyAgriculrualMachinery } from '@/api/njgl'

  export default {
    name: 'NowAddEdit',

    data() {
      return {
        isTrue: false,//是否修改的时候禁用
        machineryId: '',//绑定的机具id
        implementId: '',//绑定的农机id
        id: '',//修改单id
        type: false,
        baseUrl: window.globalUrl.HOME_API,
        activeIndex: 4,
        dialogVisible2: true,
        fileList2: [],
        title: '新增农机',
        files: [],//照片
        files2: [],//展示照片
        BigPicUrl: [],//身份证大图照片
        carTypeList: [],//车辆类型列表
        jobTypeList: [],  // 农机作业类型列表
        JobsTypeList: [],//机具作业类型列表
        machineTypeList: [],//机具类型列表
        terminalMerchant: [],//终端厂商列表
        terminalIdTypeList: [],//终端类型列表
        implementIdList: [],//绑定农机列表
        // companyAuthId: '',//合作社id
        // //车辆类型props
        // carTypeProps: {
        //   label: "description",
        //   // children: "children",
        //   value: "id",
        //   // checkStrictly: true,
        //   // emitPath: false,
        // },
        // jobProps: {
        //   label: "description",
        //   children: "children",
        //   value: "id",
        //   checkStrictly: true,
        //   emitPath: false,
        // },//作业类型props
        // machineTypeProps: {
        //   label: "description",
        //   // children: "children",
        //   value: "id",
        //   checkStrictly: true,
        //   emitPath: false,
        // },//机具类型props
        terminalIdType: {
          label: "terminalCode",
          // children: "children",
          value: "id",
          checkStrictly: true,
          emitPath: false,
        },//终端类型props
        terminalMerchantProps: {
          label: "description",
          // children: "children",
          value: "id",
          checkStrictly: true,
          emitPath: false,
        },
        implementIdProps: {
          label: "carType",
          // children: "children",
          value: "id",
          checkStrictly: true,
          emitPath: false,
        },

        //农机
        form: {
          carNumber: '',//车牌号
          vehicleIdentificationNumber: '',//车架号
          carBrand: '',//	车辆品牌
          carType: '',//	车辆类型
          jobType: '',//作业类型
          allUser: '',//所有人
          manufacturer: '',//生产厂商
          userPhone: '',//所有人手机号
          manufactureTime: '',//生产日期
          bindTerminal: '',//绑定终端名字
          terminalId: '',//绑定终端id
        },
        form2: {
          allUser: '',//所有人姓名
          implementCode: '',//机具编号
          brand: '',//品牌
          manufacturer: '',//生产厂商
          machineType: '',//机具类型
          bindTerminal: '',//绑定终端名字
          terminalId: '',//绑定终端id
          allPhone: '',//所有人手机号
          jobType: '',//作业类型
        },
        form3: {
          allUser: '',//所有人姓名
          startTime: '',//创建时间
          endTime: '',//结束时间
          terminalCode: '',//终端编号
          // vehicleIdentificationNumber: '',//车架号
          terminalMerchant: '',// 终端厂商
          // carType: '',//车辆类型
          implementId: '',//绑定机具
          machineryId: '', // 绑定农机
          temporarilyId: '',//临时的id
          allUserPhone: '',//所有人手机号
          startTime: '',//开始时间
          endTime: '',//结束时间
        },
        form4: {
          allUser: '',//所有人
          carBrand: '',//车辆品牌
          carNumber: '',//车牌号
          carType: '',//车辆类型
          manufactureTime: '',//生产日期'
          manufacturer: '',//生产厂家
          userPhone: '',//所有人手机号
          vehicleIdentificationNumber: '',//车架号
        },

        tabsList: [
          {
            name: '拖拉机',
            value: '4',
          }, {
            name: '自走机',
            value: '1',

          }, {
            name: '机具',
            value: '2',
          }, {
            name: '终端',
            value: '3',
          }
        ],

        formRules: {
          carNumber: [
            { required: true, message: '请输入车牌号', trigger: 'blur' },
          ],
          vehicleIdentificationNumber: [
            { required: true, message: '请输入车架号', trigger: 'blur' },
          ],
          carBrand: [
            { required: true, message: '请输入车牌号', trigger: 'blur' },
          ],
          carType: [
            { required: true, message: '请选择车辆类型', trigger: 'change' },
          ],
          jobType: [
            { required: true, message: '请选择作业类型', trigger: 'change' },
          ],
          manufacturer: [
            { required: true, message: '请输入生产厂家', trigger: 'blur' },
          ],
          allUser: [
            { required: true, message: '请输入所有人姓名', trigger: 'blur' },
          ],
          userPhone: [
            { required: true, message: '请输入所有人手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' }
          ],
          manufactureTime: [
            { required: true, message: '请输入生产日期', trigger: 'change' },
          ],
          // terminalId: [
          //   { required: true, message: '请选择绑定的终端', trigger: 'blur' },
          // ],
          files: [
            { required: true, validator: this.validateFiles, trigger: 'change' }
          ],
        },

        formRules2: {
          implementCode: [
            { required: true, message: '请输入机具编号', trigger: 'blur' },
          ],
          brand: [
            { required: true, message: '请输入品牌', trigger: 'blur' },
          ],
          manufacturer: [
            { required: true, message: '请输入生产厂商', trigger: 'blur' },
          ],
          machineType: [
            { required: true, message: '请选择机具类型', trigger: 'change' },
          ],
          // terminalIdTypeList: [
          //   { required: true, message: '请选择绑定的终端', trigger: 'blur' },
          // ],
          jobType: [
            { required: true, message: '请选择作业类型', trigger: 'change' },
          ],
          allPhone: [
            { required: true, message: '请输入所有人手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' }
          ],
          allUser: [
            { required: true, message: '请输入所有人姓名', trigger: 'blur' },
          ],
          files: [
            { required: true, validator: this.validateFiles, trigger: 'change' }
          ],
        },

        formRules3: {
          terminalCode: [
            { required: true, message: '请输入终端编号', trigger: 'blur' },
          ],
          // vehicleIdentificationNumber: [
          //   { required: true, message: '请输入车架号', trigger: 'blur' },
          // ],
          terminalMerchant: [
            { required: true, message: '请选择终端厂商', trigger: 'blur' },
          ],
          // carType: [
          //   { required: true, message: '请选择车辆类型', trigger: 'change' },
          // ],
          implementId: [
            { required: true, message: '请选择农机具', trigger: 'change' },
          ],
          allUserPhone: [
            { required: true, message: '请输入所有人手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' }
          ],
          startTime: [
            { required: true, message: '请选服务开始时间', trigger: 'blur' },
          ],
          endTime: [
            { required: true, message: '请选服务结束时间', trigger: 'blur' },
          ],
          allUser: [
            { required: true, message: '请输入所有人姓名', trigger: 'blur' },
          ],
          files: [
            { required: true, validator: this.validateFiles, trigger: 'change' }
          ],
        },

        formRules4: {
          allUser: [
            { required: true, message: '请输入所有人姓名', trigger: 'blur' },
          ],
          carBrand: [
            { required: true, message: '请输车辆品牌', trigger: 'blur' },
          ],
          carNumber: [
            { required: true, message: '请输入车牌号', trigger: 'blur' },
          ],
          carType: [
            { required: true, message: '请选择车辆类型', trigger: 'blur' },
          ],
          manufactureTime: [
            { required: true, message: '请选择生产日期', trigger: 'blur' },
          ],
          manufacturer: [
            { required: true, message: '请输入生产厂家', trigger: 'blur' },
          ],
          userPhone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' }
          ],
          vehicleIdentificationNumber: [
            { required: true, message: '请输入车架号', trigger: 'blur' },
          ],
          files: [
            { required: true, validator: this.validateFiles, trigger: 'change' }
          ],
        }
      };
    },

    mounted() {

    },

    methods: {
      //绑定终端农机具
      handleSelectChange(value) {

        let index = this.implementIdList.findIndex((item) => item.id == value)
        let Item = this.implementIdList[index]

        //判断绑定的是农机还是机具
        if (Item.type == 1) {
          // this.form3.carNumber=Item.carNumber
          this.form3.machineryId = Item.id
        } else {
          this.form3.implementId = Item.id
        }
      },

      // 校验手机号
      validatePhone(rule, value, callback) {
        const phoneReg = /^1[3-9]\d{9}$/;
        if (!phoneReg.test(value)) {
          callback(new Error('请输入有效的手机号'));
        } else {
          callback();
        }
      },

      // 校验文件上传
      validateFiles(rule, value, callback) {
        if (this.files.length === 0) {
          callback(new Error('请上传图片'));
        } else {
          callback();
        }
      },
      //关闭弹窗
      handleClose() {
        this.$emit("closeDialog2", false);


      },
      // 删除图片
      handleRemove2(file, files) {
        // console.log(file,'查看删除的图片')
        // console.log(files,'查看files')

        // console.log(this.files,'查看this.files')
        // console.log(this.file2,'查看files.url')

        let index = files.findIndex(item => item.url == file.url)
        if (index < 0) {
          this.$message({
            type: 'info',
            message: '未查询到选择的图片'
          })

        } else {

          this.files.splice(index, 1)
          this.BigPicUrl.splice(index, 1)
          this.files2.splice(index, 1)
        }



        // this.files.splice(this.files.indexOf(file), 1);
        // this.BigPicUrl.splice(this.BigPicUrl.indexOf(file.url), 1);
        // this.files2.splice(this.files2.indexOf(file), 1);
      },

      // 车辆类型的列表
      async getCarTypeList() {

        let res = await carType('car_type')
        if (res.code == 0) {
          this.carTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //机具-作业类型列表
      async getJobsType() {
        let res = await carType('jobs_type')
        if (res.code == 0) {
          this.JobsTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //作业类型的列表
      async getJobTypeList() {
        let res = await carType('jobs_type')
        if (res.code == 0) {
          this.jobTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //终端列表
      async selectCompanyAgriculrualMachinery() {
        let params = {
          status: this.activeIndex,
        }
        //添加id用于查找以绑定的值
        if (this.implementId || this.machineryId) {
          params.id = this.implementId || this.machineryId;
        }
        let res = await selectCompanyAgriculrualMachinery(params)
        if (res.code == 0) {
          this.terminalIdTypeList = res.data
        } else {
          this.$message({
            type: 'info',
            message: res.msg,
            duration: 2000,
          })
        }
      },
      // 终端厂商列表
      async getTerminalMerchant() {
        let res = await carType('terminal_manufacturer')
        if (res.code == 0) {
          this.terminalIdTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      // 绑定农机具
      async getImplementIdList(id) {
        // console.log('这里没执行嘛')
        let params = {
          status: this.activeIndex,
          id: id
        }
        let res = await selectCompanyAgriculrualMachinery(params)
        if (res.code == 0) {
          // let allList = [
          //   ...res.data.ldtpAgricultralMachineryVoList,
          //   ...res.data.ldtpAgriculturalImplementVoList
          // ]
          let allList = [
            ...(res.data.ldtpAgricultralMachineryVoList || []),
            ...(res.data.ldtpAgriculturalImplementVoList || [])
          ];


          // 对农机和机具列表重构，构建为下拉列表格式

          this.newImplementIdList = allList.map(item => {
            const carTypeNameOrMachineTypeName = item.carTypeName || item.machineTypeName || '';
            const brandOrCarBrand = item.brand || item.carBrand || '';
            const carNumberOrImplementCode = item.carNumber || item.implementCode || '';
            return {
              ...item,
              type: item.carNumber ? 1 : 2,
              label: `${carNumberOrImplementCode} - ${carTypeNameOrMachineTypeName} - ${brandOrCarBrand}`
            };
          });
          this.implementIdList = this.newImplementIdList
          console.log(this.newImplementIdList, '查看修改的数据格式')

        } else {
          this.$message({
            type: 'info',
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //机具类型
      async getMachineTypeList() {
        let res = await carType('implement_type')
        if (res.code == 0) {
          this.machineTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //时间开始时间校验
      changeStartTime() {
        let starttime = new Date(this.form3.startTime).getTime()
        let endtime = new Date(this.form3.endTime).getTime()
        console.log(endtime, starttime);

        if (endtime < starttime) {
          this.$message.error("结束时间不能小于开始时间");
          this.form3.startTime = ""; // 补贴申请结束时间
          // this.applyStartTime = ""; // 补贴申请开始时间
        }
      },
      //时间结束校验
      changeEndTime() {
        console.log("changeEndTime");

        let starttime = new Date(this.form3.startTime).getTime()
        let endtime = new Date(this.form3.endTime).getTime()


        if (endtime < starttime) {
          this.$message.error("结束时间不能小于开始时间");
          this.form3.endTime = ""; // 补贴申请结束时间
          // this.applyStartTime = ""; // 补贴申请开始时间
        }
      },
      //编辑回显数据
      async EditData(activeIndex, obj, type) {
        this.isTrue = true
        this.type = type
        console.log(obj, '查看obj')
        this.activeIndex = activeIndex
        let matchedTab = this.tabsList.find(tab => tab.value == activeIndex).name;
        if (type) {
          this.title = matchedTab + '详情'
        } else {
          this.title = "编辑" + matchedTab
          this.id = obj.id
        }
        let num = Number(activeIndex)
        switch (num) {
          //农机
          case 1:
            // 使用 Promise.all 来确保所有异步操作完成
            await Promise.all([
              this.getCarTypeList(),
              this.getJobTypeList(),
              this.selectCompanyAgriculrualMachinery()
            ]); const Item1 = {
              id: obj.terminalId,
              terminalCode: obj.bindTerminal
            }
             this.terminalIdTypeList.push(Item1)
            this.form.carNumber = obj.carNumber,//车牌号
              this.form.vehicleIdentificationNumber = obj.vehicleIdentificationNumber,//车架号
              this.form.carBrand = obj.carBrand,//	车辆品牌
              this.form.carType = Number(obj.carType),//	车辆类型
              this.form.jobType = Number(obj.jobType),//作业类型
              this.form.allUser = obj.allUser,//所有人
              this.form.userPhone = obj.userPhone,//所有人手机号
              this.form.manufactureTime = obj.manufactureTime,//生产日期
              this.form.bindTerminal = obj.bindTerminal,//绑定终端名字
              this.form.terminalId = obj.terminalId,//绑定终端id
              this.form.manufacturer = obj.manufacturer

            break;
          //机具
          case 2:
            console.log('这里没执行嘛')
            await Promise.all([
              this.getMachineTypeList(),
              this.getJobsType(),
              this.selectCompanyAgriculrualMachinery()

            ]);
            const Item2 = {
              id: obj.terminalId,
              terminalCode: obj.bindTerminal
            }
            this.terminalIdTypeList.push(Item2)
            console.log(this.terminalIdTypeList, '查看terminalIdTypeList')
            this.form2.allUser = obj.allUser,//所有人姓名
              this.form2.implementCode = obj.implementCode,//机具编号
              this.form2.brand = obj.brand,//品牌
              this.form2.manufacturer = obj.manufacturer,//生产厂商
              this.form2.machineType = Number(obj.machineType),//机具类型
              this.form2.bindTerminal = obj.bindTerminal,//绑定终端
              this.form2.terminalId = obj.terminalId,//绑定终端id
              this.form2.allPhone = obj.allPhone//所有人手机号
            this.form2.jobType = Number(obj.jobType) //作业类型
            console.log(this.form2.terminalId, 'this.form2.terminalId')
            console.log(this.terminalIdTypeList, '查看terminalIdTypeList')
            break;

          // 终端
          case 3:
            await Promise.all([
              this.getTerminalMerchant(),
              // this.getCarTypeList(),
              this.getImplementIdList(obj.machineryId || obj.implementId),

            ]);
            this.implementId = obj.implementId
            this.machineryId = obj.machineryId

            this.form3.allUser = obj.allUser,//所有人姓名
              this.form3.startTime = obj.startTime,//创建时间
              this.form3.endTime = obj.endTime,//结束时间
              this.form3.terminalCode = obj.terminalCode,//终端编号
              // this.form3.vehicleIdentificationNumber = obj.vehicleIdentificationNumber,//车架号
              this.form3.terminalMerchant = Number(obj.terminalMerchant),// 终端厂商
              // this.form3.carType = Number(obj.carType),//车辆类型
              this.form3.temporarilyId = obj.implementId || obj.machineryId,//绑定农机具
              this.form3.allUserPhone = obj.allUserPhone,//所有人手机号
              this.form3.startTime = obj.startTime,//开始时间
              this.form3.endTime = obj.endTime,//结束时间
              this.form3.allUser = obj.allUser//所有人姓名

            break;
          // 拖拉机
          case 4:
            // await Promise.all([
            // this.getCarTypeList()
            // ]);
            this.carTypeList = [{
              label: '拖拉机',
              id: '拖拉机'
            }]
            this.form4.allUser = obj.allUser,//所有人
              this.form4.carBrand = obj.carBrand,//车辆品牌
              this.form4.carNumber = obj.carNumber,//车牌号
              this.form4.carType = obj.carType,//车辆类型
              this.form4.manufactureTime = obj.manufactureTime,//生产日期'
              this.form4.manufacturer = obj.manufacturer,//生产厂家
              this.form4.userPhone = obj.userPhone,//所有人手机号
              this.form4.vehicleIdentificationNumber = obj.vehicleIdentificationNumber //车架号
            console.log(this.form.carType, '查看此数据')
            break;
        }

        this.files = obj.files ? JSON.parse(obj.files) : []
        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            this.files2.push({
              FilesName: this.files[i].FilesName,
              url: this.baseUrl + 'admin/file/get?ossFilePath=' + this.files[i].url
            })
          }
        }



      },
      // 新增回显数据
      initData2(activeIndex) {
        console.log(activeIndex, '查看activeIndex')
        const matchedTab = this.tabsList.find(tab => tab.value == activeIndex).name;
        console.log(matchedTab, 'matchedTab')
        this.activeIndex = activeIndex
        this.title = "新增" + matchedTab
        console.log(this.activeIndex, '查看activeIndex')
        if (activeIndex == 1) {
          this.getCarTypeList()
          this.getJobTypeList()
          this.selectCompanyAgriculrualMachinery()
        } else if (activeIndex == 2) {
          this.getMachineTypeList()
          this.getJobsType()
          this.selectCompanyAgriculrualMachinery()
        } else if (activeIndex == 3) {
          this.getTerminalMerchant()
          // this.getCarTypeList()
          // this.getImplementIdList()
          this.getImplementIdList(this.machineryId || this.implementId)
          // this.selectCompanyAgriculrualMachinery()

        } else if (activeIndex == 4) {
          // this.getCarTypeList()
          this.form4.carType = '拖拉机'
          this.carTypeList = [{
            label: '拖拉机',
            id: '拖拉机'
          }]
        }
      },

      // 绑定终端-农机
      handleChange(value) {
        let foundValue = ''; // 将 const 改为 let
        let activeIndex = Number(this.activeIndex)
        switch (activeIndex) {
          case 1:
            console.log('查看执行没')
            foundValue = this.terminalIdTypeList.find(item => item.id == value);
            this.form.bindTerminal = foundValue.terminalCode;
            break;
          case 2:
            foundValue = this.terminalIdTypeList.find(item => item.id == value);
            this.form2.bindTerminal = foundValue.terminalCode;
            break;
          case 3:
            break;
          case 4:
            break;
        }

      },

      // 终端厂商-终端
      regionChange6() {
        let node = this.$refs.terminalMerchant.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node, '查看node')
        // this.form2.bindTerminal = node.label
        // this.form2.terminalId = node.value;

        setTimeout(() => {
          this.$refs.form3.validateField("terminalMerchant");
        }, 100);
      },

      // 绑定农机具-终端
      // regionChange8() {
      //   let node = this.$refs.implementId.getCheckedNodes()[0];
      //   console.log(node, '查看node')
      //   this.areaHasChild = node.hasChildren;
      //   this.form3.implementId = node.id;
      //   setTimeout(() => {
      //     this.$refs.form3.validateField("implementId");
      //   }, 100);
      // },

      // 预览照片
      handlePictureCardPreview2(file) {
        this.BigPicUrl = []
        this.BigPicUrl.push(file.url)
        console.log(this.BigPicUrl)
        this.$nextTick(() => {
          this.$refs.preview2.clickHandler();
        });
      },


      async submitForm() {
        let params = {};
        let activeIndex = Number(this.activeIndex);
        let formName = '';

        // 根据 activeIndex 选择不同的表单对象
        switch (activeIndex) {
          case 1:
            formName = 'form';
            params.ldtpAgricultralMachineryDto = this.prepareParams(this.form);
            break;
          case 2:
            formName = 'form2';
            params.ldtpAgriculturalImplementDto = this.prepareParams(this.form2);
            break;

          case 3:
            formName = 'form3';

            params.ldtpAgriculturalTerminalDto = this.prepareParams(this.form3);
            break;
          case 4:
            formName = 'form4';
            params.ldtpAgriculruralTractorDto = this.prepareParams(this.form4);
            break;
        }
        // 校验表单
        try {
          await this.$refs[formName].validate();

        } catch (error) {
          // console.error('表单校验失败', error);
          this.$message({
            type: 'error',
            message: '请填写必填项',
            duration: 2000,
          });
          return;
        }

        // 添加 status 字段
        params.status = activeIndex;
        if (this.activeIndex == 3) {
          delete params.ldtpAgriculturalTerminalDto.temporarilyId; // 删除属性名为 a 的属性
        }
        console.log(params, '查看params')
        let res = await insertAgriculturalImplement(params);

        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
            duration: 2000,
          });
          this.handleClose();
        } else {
          this.$message({
            type: 'info',
            message: res.msg,
            duration: 2000,
          });
        }
      },

      prepareParams(form) {

        return {
          ...form,
          files: this.files.length > 0 ? JSON.stringify(this.files) : [],
          ...(this.id && { id: this.id })
        };
      },




      //上传图片
      async uploadImages(file) {
        let param = new FormData();
        param.append("catalog", "register");
        param.append("file", file.raw);
        let res = await uploadFile(param);

        let FilesName = res.data.sourceFileName;
        let url = res.data.url;
        let item =
        {
          url: '',
          FilesName: ''
        }
        let item2 = {
          url: '',
          FilesName: ''
        }

        // console.log(FilesName, url,)
        item.url = url
        item.FilesName = FilesName
        item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
        item2.FilesName = FilesName
        this.files.push(item)
        this.files2.push(item2)


        let num = Number(this.activeIndex)
        switch (num) {
          case 1:
            this.$refs.form.clearValidate('files'); // 假设licenseAddress是需要清除校验的字段
            break;
          case 2:
            this.$refs.form2.clearValidate('files'); // 假设licenseAddress是需要清除校验的字段
            break;
          case 3:
            this.$refs.form3.clearValidate('files'); // 假设licenseAddress是需要清除校验的字段
            break;
          case 4:
            this.$refs.form4.clearValidate('files'); // 假设licenseAddress是需要清除校验的字段
            break;
        }



      },
    },
  };
</script>


<style lang="less" scoped>
  /* /deep/.el-dialog__header {
    background: white !important
  } */


  .content {
    width: 100%;
    height: 100%;
  }

  .ItemIpt {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    /deep/.el-form-item {
      padding: 0;
      margin: 5px 0px;
      width: 350px !important;

      .el-form-item__content {
        width: 200px;

        .el-date-editor {
          width: 200px !important;
        }

        .el-cascader {
          width: 200px !important;
        }
      }
    }
  }

  .up-img {
    margin-top: 30px;
  }



  /* .up-img {
    padding-top: 20px;

    /deep/.el-upload {
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    /deep/.upload-demo {
      display: flex;

      .el-upload-list__item {
        width: 100px;
        height: 100px;

        img {
          width: 100px;
          height: 100px;
        }

      }
    }

    img {
      width: 100px;
      height: 100px;
      margin-left: 20px;
    }
  } */

  /* 提交申报*/
  .footer {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
  }

  .no-image {
    /deep/.el-upload {
      display: none !important;
    }
  }
</style>