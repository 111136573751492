export const njglColumn = [
  {
    prop: "companyName",
    label: "农机所有人",
    // width: 100,
    // template: true,
  },
  {
    prop: "tractor",
    label: "拖拉机(台)",
  },

  {
    prop: "implement",
    label: "农机具(台)",
  },
  {
    prop: "machinery",
    label: "自走农机(台)",
  },
  {
    prop: "terminal",
    label: "终端(台)",
    // width: 80,
    // template: true
  },
  {
    prop: "operation",
    label: "操作",
    // width: 280,
    template: true,
  },
]
// 农机管理 - 农机

export const njglDetailColumn = [
  {
    prop: "carTypeName",
    label: "设备类型",
    // width: 100,
    // template: true,
  },
  {
    prop: "carNumber",
    label: "农机编号/车牌号",
  },

  {
    prop: "carBrand",
    label: "品牌",
  },
  {
    prop: "bindTerminal",
    label: "绑定终端",
  },
  {
    prop: "manufactureTime",
    label: "终端开始时间",
    // width: 80,
    // template: true
  },

  {
    prop: "operation",
    label: "操作",
    // width: 280,
    template: true,
  },
]

// 农机管理-机具
export const njDetailColumn = [
  {
    prop: "implementCode",
    label: "机具编号",
    // width: 100,
    // template: true,
  },
  {
    prop: "brand",
    label: "品牌",
  },

  {
    prop: "manufacturer",
    label: "生产厂商",
  },
  {
    prop: "machineTypeName",
    label: "机具类型",
  },
  {
    prop: "bindTerminal",
    label: "绑定终端",
    // width: 80,
    // template: true
  },
  {
    prop: "allPhone",
    label: "所有人手机号",
    // width: 80,
    // template: true
  },
  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]
//农机管理-终端
export const zdDetailColumn = [
  {
    prop: "terminalCode",
    label: "终端编号",
    width: 100,
    // template: true,
  },
  // {
  //   prop: "vehicleIdentificationNumber",
  //   label: "车架号",
  // },

  {
    prop: "terminalMerchantName",
    label: "终端厂商",
  },
  // {
  //   prop: "carType",
  //   label: "车辆类型",
  // },
  {
    prop: "startTime",
    label: "服务开始时间",
    // width: 80,
    // template: true
  },
  {
    prop: "endTime",
    label: "服务结束时间",
    // width: 80,
    // template: true
  },
  {
    prop: "operation",
    label: "操作",
    // width: 280,
    template: true,
  },
]
export const tljColumn = [
  {
    prop: "carNumber",
    label: "车牌号",
    // width: 100,
    // template: true,
  },
  {
    prop: "manufactureTime",
    label: "生产日期",
  },

  {
    prop: "manufacturer",
    label: "生产厂家",
  },
  {
    prop: "userPhone",
    label: "所有人手机号",
  },
  {
    prop: "vehicleIdentificationNumber",
    label: "车架号",
    // width: 80,
    // template: true
  },

  {
    prop: "operation",
    label: "操作",
    // width: 280,
    template: true,
  },
]
//配置农机
export const njpzNjColumn = [
  {
    prop: "carTypeName",
    label: "设备类型",
    // width: 100,
    // template: true,
  },
  {
    prop: "carNumber",
    label: "农机编号/车牌号",
  },

  {
    prop: "carBrand",
    label: "品牌",
  },
  {
    prop: "bindTerminal",
    label: "绑定终端",
  },
  {
    prop: "manufactureTime",
    label: "终端服务有效期",

  },

]
// 配置机具
export const jjpzColumn = [
  {
    prop: "implementCode",
    label: "机具编号",
    // width: 100,
    // template: true,
  },
  {
    prop: "brand",
    label: "品牌",
  },

  {
    prop: "manufacturer",
    label: "生产厂商",
  },
  {
    prop: "machineType",
    label: "机具类型",
  },
  {
    prop: "bindTerminal",
    label: "绑定终端",
    // width: 80,
    // template: true
  },
  {
    prop: "allPhone",
    label: "所有人手机号",
    // width: 80,
    // template: true
  },


]
// 我的农机-农机
// export const wdnjColumn = [
//   {
//     prop: "orderType",
//     label: "设备类型",
//     width: 100,
//     // template: true,
//   },
//   {
//     prop: "cropCode",
//     label: "农机编号/车牌号",
//   },

//   {
//     prop: "landArea",
//     label: "品牌",
//   },
//   {
//     prop: "estimatePrice",
//     label: "绑定终端",
//   },
//   {
//     prop: "orderState",
//     label: "终端服务有效期",
//     width: 80,
//     template: true
//   },
//   {
//     prop: "operation",
//     label: "操作",
//     width: 280,
//     template: true,
//   },
// ]



